import React, { useState } from 'react';

interface SelectGroupToolsProps {
  onChange: (selectedTools: string[]) => void;
}

interface Option {
  id: string;
  label: string;
}

const options: Option[] = [
  { id: '1', label: 'Agenda' },
  { id: '2', label: 'WhatsApp' },
];

const SelectGroupTools: React.FC<SelectGroupToolsProps> = ({ onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleCheckboxChange = (value: string) => {
    setSelectedOptions((prev) => {
      const newSelection = prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value];

      // Passando as opções selecionadas para o componente pai através da prop onChange
      onChange(newSelection);

      return newSelection;
    });
  };

  return (
    <div className="mb-5.5">
      <label
        htmlFor="roleSelect"
        className="mb-4.5 block text-sm font-medium text-black dark:text-white"
      >
        Ferramentas <span className="text-meta-1">*</span>
      </label>

      <div className="flex flex-wrap items-center gap-5.5">
        {options.map((option) => (
          <div key={option.id}>
            <label className="relative flex cursor-pointer select-none items-center gap-2 text-sm font-medium text-black dark:text-white">
              <input
                className="sr-only"
                type="checkbox"
                name="roleSelect"
                id={option.id}
                checked={selectedOptions.includes(option.id)}
                onChange={() => handleCheckboxChange(option.id)} // Interno
              />
              <span
                className={`flex h-5 w-5 items-center justify-center rounded border ${
                  selectedOptions.includes(option.id) ? 'border-virtuspurple' : 'border-body'
                }`}
              >
                <span
                  className={`h-2.5 w-2.5 rounded bg-virtuspurple ${
                    selectedOptions.includes(option.id) ? 'flex' : 'hidden'
                  }`}
                ></span>
              </span>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectGroupTools;