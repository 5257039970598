// import React from 'react';
// import DefaultLayout from '../../layout/DefaultLayout';
// import SelectGroupModelAgentProfile from '../../components/Forms/SelectGroup/SelectGroupModelAgentProfile';
// import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
// // import SelectGroupPostura from '../../components/Forms/SelectGroup/SelectGroupPostura';
// // import ListAgentKn from '../../components/ListAgentKn';
// import userOne from '@images/user/user-01.png';

// const AgentCreationPage: React.FC = () => {
//   return (
//     <DefaultLayout>
//       <Breadcrumb pageName="Agente" />
//       {/* <DataStatsThree /> */}

//       <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
//         <div className="col-span-12 xl:col-span-12  \">
//             {/* <!-- Contact Form --> */}
//             <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
//               <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
//                 <h3 className="font-medium text-black dark:text-white">
//                   Perfil do agente
//                 </h3>
//                 <p>Defina como o agente deve ser, personalidade do agente em suas interações.</p>
//               </div>
//               <form action="#">
//                 <div className="p-6.5">
//                   <div className="mb-4.5">
//                     <div className="mt-0.5 z-5 relative -mt-10 h-30 w-full max-w-30 rounded-full bg-white/20 p-1 backdrop-blur sm:h-44 sm:max-w-44 sm:p-3">
//                       <div className="relative drop-shadow-2">
//                         <img src={userOne} alt="profile" />
//                           <label
//                             htmlFor="profile"
//                             className="absolute bottom-0 right-0 flex h-8.5 w-8.5 cursor-pointer items-center justify-center rounded-full bg-virtuspurple text-white hover:bg-opacity-90 sm:bottom-2 sm:right-10">
//                             <svg
//                               className="fill-current"
//                               width="14"
//                               height="14"
//                               viewBox="0 0 14 14"
//                               fill="none"
//                               xmlns="http://www.w3.org/2000/svg"
//                             >
//                               <path
//                                 fillRule="evenodd"
//                                 clipRule="evenodd"
//                                 d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638ZM5.56219 2.33329L4.5687 3.82353C4.46051 3.98582 4.27837 4.08329 4.08333 4.08329H1.75C1.59529 4.08329 1.44692 4.14475 1.33752 4.25415C1.22812 4.36354 1.16667 4.51192 1.16667 4.66663V11.0833C1.16667 11.238 1.22812 11.3864 1.33752 11.4958C1.44692 11.6052 1.59529 11.6666 1.75 11.6666H12.25C12.4047 11.6666 12.5531 11.6052 12.6625 11.4958C12.7719 11.3864 12.8333 11.238 12.8333 11.0833V4.66663C12.8333 4.51192 12.7719 4.36354 12.6625 4.25415C12.5531 4.14475 12.4047 4.08329 12.25 4.08329H9.91667C9.72163 4.08329 9.53949 3.98582 9.4313 3.82353L8.43781 2.33329H5.56219Z"
//                                 fill=""
//                               />
//                               <path
//                                 fillRule="evenodd"
//                                 clipRule="evenodd"
//                                 d="M7.00004 5.83329C6.03354 5.83329 5.25004 6.61679 5.25004 7.58329C5.25004 8.54979 6.03354 9.33329 7.00004 9.33329C7.96654 9.33329 8.75004 8.54979 8.75004 7.58329C8.75004 6.61679 7.96654 5.83329 7.00004 5.83329ZM4.08337 7.58329C4.08337 5.97246 5.38921 4.66663 7.00004 4.66663C8.61087 4.66663 9.91671 5.97246 9.91671 7.58329C9.91671 9.19412 8.61087 10.5 7.00004 10.5C5.38921 10.5 4.08337 9.19412 4.08337 7.58329Z"
//                                 fill=""
//                               />
//                               </svg>
//                                 <input
//                                   type="file"
//                                   name="profile"
//                                   id="profile"
//                                   className="sr-only"
//                                 />
//                           </label>
//                         </div>
//                       </div>
                      
//                       <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
//                         <div className="w-full ">
//                           <label className="mb-2.5 block text-black dark:text-white">
//                             Nome<span className="text-meta-1">*</span>
//                           </label>

//                           <input
//                             type="text"
//                             placeholder="Insira o nome do agente"
//                             className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
//                           />
//                         </div>
//                       </div>
                      
//                       <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
//                         <div className="w-full xl:w-1/2">
//                           <div className="mb-6">
//                             <label className="mb-2.5 block text-black dark:text-white">
//                               Finalidade <span className="text-meta-1">*</span>
//                             </label>
//                             <p>Especifique a função e as tarefas do agente.</p>
//                             <textarea
//                               style={{ resize: 'none', height: '80px' }}
//                               placeholder="Insira"
//                               className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
//                             ></textarea>
//                           </div>
//                         </div>
                        
//                         <div className="w-full xl:w-1/2">
//                           <div className="mb-2.5">
//                             <label className="mb-2.5 block text-black dark:text-white">
//                               Restrições <span className="text-meta-1">*</span>
//                             </label>
//                             <p>Defina ações e respostas que o agente deve evitar.</p>
//                             <textarea
//                               style={{ resize: 'none', height: '80px' }}
//                               placeholder="Insira"
//                               className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
//                             ></textarea>
//                           </div>
//                         </div>
//                       </div>
//                   </div>
// {/* 
//                   <SelectGroupPostura />
//                   <SelectGroupModelAgentProfile /> */}

//                   <button className="mt-10 inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10">
//                     Send Message
//                   </button>

//                 </div>
//               </form>
//             </div>
//           </div>


//           {/* <ListAgentKn/> */}

//         </div>
//     </DefaultLayout>
//   );
// };

// export default AgentCreationPage;

// import React, { useState } from 'react';
// import DefaultLayout from '../../layout/DefaultLayout';
// import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
// import userOne from '@images/user/user-01.png';
// import SelectGroupPostura from '../../components/Forms/SelectGroup/SelectGroupPostura';
// import SelectGroupModelAgentProfile from '../../components/Forms/SelectGroup/SelectGroupModelAgentProfile';

// const AgentCreationPage: React.FC = () => {
//   const [profileImage, setProfileImage] = useState<File | null>(null);
//   const [agentName, setAgentName] = useState('');
//   const [purpose, setPurpose] = useState('');
//   const [restrictions, setRestrictions] = useState('');
//   const [postures, setPostures] = useState<string[]>([]);
//   const [modelProfile, setModelProfile] = useState<string>('');

//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0] || null;
//     setProfileImage(file);
//   };

//   const handlePostureChange = (selectedPostures: string[]) => {
//     setPostures(selectedPostures);
//   };

//   const handleModelProfileChange = (model: string) => {
//     setModelProfile(model);
//   };

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();

//     const formData = new FormData();
//     if (profileImage) {
//       formData.append('profileImage', profileImage);
//     }
//     formData.append('agentName', agentName);
//     formData.append('purpose', purpose);
//     formData.append('restrictions', restrictions);
//     formData.append('postures', JSON.stringify(postures));
//     formData.append('modelProfile', modelProfile);

//     try {
//       const response = await fetch('/api/agents', {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Erro ao enviar os dados');
//       }

//       alert('Agente criado com sucesso!');
//     } catch (error) {
//       console.error('Erro:', error);
//       alert('Erro ao criar agente');
//     }
//   };

//   return (
//     <DefaultLayout>
//       <Breadcrumb pageName="Agente" />

//       <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
//         <div className="col-span-12 xl:col-span-12">
//           <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
//             <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
//               <h3 className="font-medium text-black dark:text-white">Perfil do agente</h3>
//               <p>Defina como o agente deve ser, personalidade do agente em suas interações.</p>
//             </div>
//             <form onSubmit={handleSubmit} className="p-6.5">
//               <div className="mb-4.5">
//                 <div className="mt-0.5 z-5 relative -mt-10 h-44 w-44 max-w-44 rounded-full  p-1 backdrop-blur sm:h-44 sm:max-w-44 sm:p-3">
//                   <div className="relative drop-shadow-2">
//                     <img 
//                       src={profileImage ? URL.createObjectURL(profileImage) : userOne} 
//                       alt="profile" 
//                       className="h-full w-full object-cover rounded-full" 
//                     />
//                     <label htmlFor="profile" className="absolute bottom-0 right-0 flex h-8.5 w-8.5 cursor-pointer items-center justify-center rounded-full bg-virtuspurple text-white hover:bg-opacity-90 sm:bottom-2 sm:right-10">
//                       <svg className="fill-current" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path fillRule="evenodd" clipRule="evenodd" d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638Z" fill="" />
//                       </svg>
//                       <input type="file" name="profile" id="profile" className="sr-only" onChange={handleFileChange} />
//                     </label>
//                   </div>
//                 </div>
//               </div>

//               <div className="mb-4.5">
//                 <label className="mb-2.5 block text-black dark:text-white">Nome<span className="text-meta-1">*</span></label>
//                 <input 
//                   type="text" 
//                   placeholder="Insira o nome do agente" 
//                   className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary" 
//                   value={agentName} 
//                   onChange={(e) => setAgentName(e.target.value)} 
//                   required 
//                 />
//               </div>

//               <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
//                 <div className="w-full xl:w-1/2">
//                   <div className="">
//                     <label className="mb-2.5 block text-black dark:text-white">
//                       Finalidade <span className="text-meta-1">*</span>
//                     </label>
//                     <p>Especifique a função e as tarefas do agente.</p>
//                     <textarea
//                       placeholder="Descreva a finalidade do agente" 
//                       className="w-full rounded border-[1.5px] border-stroke py-3 px-5 text-black outline-none" 
//                       value={purpose} 
//                       onChange={(e) => setPurpose(e.target.value)}
//                       required
//                     ></textarea>
//                   </div>
//                 </div>
                
//                 <div className="w-full xl:w-1/2">
//                   <div className="">
//                     <label className="mb-2.5 block text-black dark:text-white">
//                       Restrições <span className="text-meta-1">*</span>
//                     </label>
//                     <p>Defina ações e respostas que o agente deve evitar.</p>
//                     <textarea
//                       placeholder="Liste as restrições do agente" 
//                       className="w-full rounded border-[1.5px] border-stroke py-3 px-5 text-black outline-none" 
//                       value={restrictions} 
//                       onChange={(e) => setRestrictions(e.target.value)}
//                       required
//                     ></textarea>
//                   </div>
//                 </div>
//               </div>

//               <SelectGroupModelAgentProfile onChange={handleModelProfileChange} />
//               <SelectGroupPostura onChange={handlePostureChange} />

//               <button 
//                 type="submit" 
//                 className="inline-flex items-center justify-center rounded bg-primary py-2 px-6 text-white hover:bg-opacity-90"
//               >
//                 Criar Agente
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </DefaultLayout>
//   );
// };

// export default AgentCreationPage;


import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DefaultLayout from '../../layout/DefaultLayout';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import userOne from '@images/user/user-01.png';
import SelectGroupPostura from '../../components/Forms/SelectGroup/SelectGroupPostura';
import SelectGroupModelAgentProfile from '../../components/Forms/SelectGroup/SelectGroupModelAgentProfile';
import SelectGroupTools from '../../components/Forms/SelectGroup/SelectGroupTools';

const AgentCreationPage: React.FC = () => {
  const [profile_image, setprofile_image] = useState<File | null>(null);
  const [agentName, setAgentName] = useState('');
  const [Objective, setObjective] = useState('');
  const [restrictions, setRestrictions] = useState('');
  const [personality, setpersonality] = useState<string[]>([]);
  const [tools, setTools] = useState<string[]>([]);
  const [modelProfile, setModelProfile] = useState<string>('');
  

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setprofile_image(file);
  };

  const handlePostureChange = (selectedpersonality: string[]) => {
    setpersonality(selectedpersonality);
  };

  const handleToolsChange = (selectedTools: string[]) => {
    setTools(selectedTools);
  };

  const handleModelProfileChange = (model: string) => {
    setModelProfile(model);
  };

  // Função de validação
  const isFormValid = () => {
    return (
      agentName.trim() !== '' &&
      Objective.trim() !== '' &&
      restrictions.trim() !== '' &&
      personality.length > 0 &&
      tools.length > 0 &&
      modelProfile !== '' &&
      profile_image !== null
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();
    if (profile_image) {
      formData.append('profile_image', profile_image);
    }
    formData.append('agentName', agentName);
    formData.append('purpose', Objective);
    formData.append('restrictions', restrictions);
    formData.append('personality', JSON.stringify(personality));
    formData.append('tools', JSON.stringify(tools));
    formData.append('modelProfile', modelProfile);
    

    formData.forEach((value, key) => {
      console.log(`${key}:`, value);
    });

    try {
      const response = await fetch('/api/agents', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Erro ao enviar os dados');
      }

      alert('Agente criado com sucesso!');
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao criar agente');
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Agente" />

      <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-12 xl:col-span-12">
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">Perfil do agente</h3>
              <p>Defina como o agente deve ser, personalidade do agente em suas interações.</p>
            </div>
            <form onSubmit={handleSubmit} className="p-6.5">
              <div className="mb-4.5">
                <div className="mt-0.5 z-5 relative -mt-10 h-44 w-44 max-w-44 rounded-full  p-1 backdrop-blur sm:h-44 sm:max-w-44 sm:p-3">
                  <div className="relative drop-shadow-2">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Imagem do agente <span className="text-meta-1">*</span>
                    </label>
                    <img 
                      src={profile_image ? URL.createObjectURL(profile_image) : userOne} 
                      alt="profile" 
                      className="h-full w-full object-cover rounded-full" 
                    />
                    <label htmlFor="profile" className="absolute bottom-0 right-0 flex h-8.5 w-8.5 cursor-pointer items-center justify-center rounded-full bg-virtuspurple text-white hover:bg-opacity-90 sm:bottom-2 sm:right-10">
                      <svg className="fill-current" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638Z" fill="" />
                      </svg>
                      <input type="file" name="profile" id="profile" className="sr-only" onChange={handleFileChange} />
                    </label>
                  </div>
                </div>
              </div>

              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">Nome<span className="text-meta-1">*</span></label>
                <input 
                  type="text" 
                  placeholder="Insira o nome do agente" 
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary" 
                  value={agentName} 
                  onChange={(e) => setAgentName(e.target.value)} 
                  required 
                />
              </div>

              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full xl:w-1/2">
                  <div className="">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Finalidade <span className="text-meta-1">*</span>
                    </label>
                    <p>Especifique a função e as tarefas do agente.</p>
                    <textarea
                      placeholder="Descreva a finalidade do agente" 
                      className="w-full rounded border-[1.5px] border-stroke py-3 px-5 text-black outline-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary" 
                      value={Objective} 
                      onChange={(e) => setObjective(e.target.value)}
                      required
                    ></textarea>
                  </div>
                </div>
                
                <div className="w-full xl:w-1/2">
                  <div className="">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Restrições <span className="text-meta-1">*</span>
                    </label>
                    <p>Defina ações e respostas que o agente deve evitar.</p>
                    <textarea
                      placeholder="Liste as restrições do agente" 
                      className="w-full rounded border-[1.5px] border-stroke py-3 px-5 text-black outline-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary" 
                      value={restrictions} 
                      onChange={(e) => setRestrictions(e.target.value)}
                      required
                    ></textarea>
                  </div>
                </div>
              </div>

              <SelectGroupModelAgentProfile onChange={handleModelProfileChange} />
              <SelectGroupPostura onChange={handlePostureChange} />
              <SelectGroupTools onChange={handleToolsChange} />


              <div className="flex items-center"> {/* Flex container */}
                {/* <ModalCancelarAgente /> */}

                <div className="mt-4 flex justify-start">
                  <Link
                    to="/"
                    className="inline-flex items-center justify-center rounded bg-red py-2 px-6 text-white hover:bg-opacity-90"
                  >
                    Cancelar
                  </Link>
                </div>



                <button
                  type="submit"
                  disabled={!isFormValid()}
                  className={`inline-flex items-center justify-center rounded bg-[#EFEFEF] py-2 px-6 text-white disabled:cursor-not-allowed hover:bg-opacity-90 ${
                    isFormValid()
                      ? 'bg-virtuspurple hover:bg-virtuspurple/90' 
                      : 'bg-[#EFEFEF]'
                    } ml-2 mt-4`}
                >
                  Criar Agente
                </button>

              </div>   
            </form>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AgentCreationPage;


