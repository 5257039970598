// import React, { useState } from 'react';

// interface Option {
//   id: string;
//   label: string;
// }

// const options: Option[] = [
//   { id: 'Amistoso', label: 'Amistoso' },
//   { id: 'Profissional', label: 'Profissional' },
//   { id: 'Conciso', label: 'Conciso' },
//   { id: 'Analitico', label: 'Analítico' },
//   { id: 'Criativo', label: 'Criativo' },
//   { id: 'Ousado', label: 'Ousado' },
//   { id: 'Polido', label: 'Polido' },
// ];

// const SelectGroupPostura: React.FC = () => {
//   const [isChecked, setIsChecked] = useState<string>('');

//   const handleRadioChange = (value: string) => {
//     setIsChecked(value);
//   };
//   return (
//     <div className="mb-5.5">
//       <label
//         htmlFor="roleSelect"
//         className="mb-4.5 block text-sm font-medium text-black dark:text-white"
//       >
//         Postura
//       </label>

//       <div className="flex flex-wrap items-center gap-5.5">
//         {options.map((option) => (
//           <div key={option.id}>
//             <label className="relative flex cursor-pointer select-none items-center gap-2 text-sm font-medium text-black dark:text-white">
//               <input
//                 className="sr-only"
//                 type="radio"
//                 name="roleSelect"
//                 id={option.id}
//                 onChange={() => handleRadioChange(option.id)}
//               />
//               <span
//                 className={`flex h-5 w-5 items-center justify-center rounded-full border ${
//                   isChecked === option.id ? 'border-primary' : 'border-body'
//                 }`}
//               >
//                 <span
//                   className={`h-2.5 w-2.5 rounded-full bg-primary ${
//                     isChecked === option.id ? 'flex' : 'hidden'
//                   }`}
//                 ></span>
//               </span>
//               {option.label}
//             </label>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default SelectGroupPostura;

// import React, { useState } from 'react';

// interface Option {
//   id: string;
//   label: string;
// }

// const options: Option[] = [
//   { id: 'Amistoso', label: 'Amistoso' },
//   { id: 'Profissional', label: 'Profissional' },
//   { id: 'Conciso', label: 'Conciso' },
//   { id: 'Analitico', label: 'Analítico' },
//   { id: 'Criativo', label: 'Criativo' },
//   { id: 'Ousado', label: 'Ousado' },
//   { id: 'Polido', label: 'Polido' },
// ];

// const SelectGroupPostura: React.FC = () => {
//   const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

//   const handleCheckboxChange = (value: string) => {
//     setSelectedOptions((prev) =>
//       prev.includes(value)
//         ? prev.filter((option) => option !== value)
//         : [...prev, value]
//     );
//   };

//   return (
//     <div className="mb-5.5">
//       <label
//         htmlFor="roleSelect"
//         className="mb-4.5 block text-sm font-medium text-black dark:text-white"
//       >
//         Postura
//       </label>

//       <div className="flex flex-wrap items-center gap-5.5">
//         {options.map((option) => (
//           <div key={option.id}>
//             <label className="relative flex cursor-pointer select-none items-center gap-2 text-sm font-medium text-black dark:text-white">
//               <input
//                 className="sr-only"
//                 type="checkbox"
//                 name="roleSelect"
//                 id={option.id}
//                 checked={selectedOptions.includes(option.id)}
//                 onChange={() => handleCheckboxChange(option.id)}
//               />
//               <span
//                 className={`flex h-5 w-5 items-center justify-center rounded border ${
//                   selectedOptions.includes(option.id)
//                     ? 'border-primary'
//                     : 'border-body'
//                 }`}
//               >
//                 <span
//                   className={`h-2.5 w-2.5 rounded bg-primary ${
//                     selectedOptions.includes(option.id) ? 'flex' : 'hidden'
//                   }`}
//                 ></span>
//               </span>
//               {option.label}
//             </label>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default SelectGroupPostura;

// import React from 'react';

// interface SelectGroupPosturaProps {
//   onChange: (selectedPostures: string[]) => void; // A mudança deve lidar com um array de posturas
// }

// const SelectGroupPostura: React.FC<SelectGroupPosturaProps> = ({ onChange }) => {
//   const [selectedPostures, setSelectedPostures] = React.useState<string[]>([]);

//   const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const value = e.target.value;

//     setSelectedPostures(prevSelectedPostures => {
//       if (prevSelectedPostures.includes(value)) {
//         // Se a postura já foi selecionada, remove da lista
//         return prevSelectedPostures.filter(posture => posture !== value);
//       } else {
//         // Se não, adiciona na lista
//         return [...prevSelectedPostures, value];
//       }
//     });
//   };

//   React.useEffect(() => {
//     onChange(selectedPostures);  // Passa as posturas selecionadas para o componente pai
//   }, [selectedPostures, onChange]);

//   return (
//     <div className="mb-4.5">
//       <label className="mb-2.5 block text-black dark:text-white">Posturas</label>
//       <div className="space-y-2">
//         <label>
//           <input
//             type="checkbox"
//             value="postura1"
//             checked={selectedPostures.includes('postura1')}
//             onChange={handleCheckboxChange}
//             className="mr-2"
//           />
//           Postura 1
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="postura2"
//             checked={selectedPostures.includes('postura2')}
//             onChange={handleCheckboxChange}
//             className="mr-2"
//           />
//           Postura 2
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="postura3"
//             checked={selectedPostures.includes('postura3')}
//             onChange={handleCheckboxChange}
//             className="mr-2"
//           />
//           Postura 3
//         </label>
//       </div>
//     </div>
//   );
// };

// export default SelectGroupPostura;


import React, { useState } from 'react';

// Definindo a interface para a prop onChange
interface SelectGroupPosturaProps {
  onChange: (selectedpersonality: string[]) => void;
}

interface Option {
  id: string;
  label: string;
}

const options: Option[] = [
  { id: '1', label: 'Amistoso' },
  { id: '2', label: 'Profissional' },
  { id: '3', label: 'Conciso' },
  { id: '4', label: 'Analítico' },
  { id: '5', label: 'Criativo' },
  { id: '6', label: 'Ousado' },
  { id: '7', label: 'Polido' },
];

const SelectGroupPostura: React.FC<SelectGroupPosturaProps> = ({ onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleCheckboxChange = (value: string) => {
    setSelectedOptions((prev) => {
      const newSelection = prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value];

      // Passando as opções selecionadas para o componente pai através da prop onChange
      onChange(newSelection);

      return newSelection;
    });
  };

  return (
    <div className="mb-5.5">
      <label
        htmlFor="roleSelect"
        className="mb-4.5 block text-sm font-medium text-black dark:text-white"
      >
        Postura <span className="text-meta-1">*</span>
      </label>

      <div className="flex flex-wrap items-center gap-5.5">
        {options.map((option) => (
          <div key={option.id}>
            <label className="relative flex cursor-pointer select-none items-center gap-2 text-sm font-medium text-black dark:text-white">
              <input
                className="sr-only"
                type="checkbox"
                name="roleSelect"
                id={option.id}
                checked={selectedOptions.includes(option.id)}
                onChange={() => handleCheckboxChange(option.id)} // Interno
              />
              <span
                className={`flex h-5 w-5 items-center justify-center rounded border ${
                  selectedOptions.includes(option.id) ? 'border-virtuspurple' : 'border-body'
                }`}
              >
                <span
                  className={`h-2.5 w-2.5 rounded bg-virtuspurple ${
                    selectedOptions.includes(option.id) ? 'flex' : 'hidden'
                  }`}
                ></span>
              </span>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectGroupPostura;