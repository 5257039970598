// import React, { useState } from 'react';

// const SelectGroupModelAgentProfile: React.FC = () => {
//   const [selectedOption, setSelectedOption] = useState<string>('');
//   const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);

//   const changeTextColor = () => {
//     setIsOptionSelected(true);
//   };

//   return (
//     <div className="mt-6">
//       <label className="mb-2.5 block text-azul_medio dark:text-white">
//         {' '}
//         Modelo <span className="text-meta-1">*</span>{' '}
//       </label>
//       <p>Selecione o modelo de IA que o agente deve utilizar em suas respostas.</p>

//       <div className="relative z-20 bg-transparent dark:bg-form-input">
//         <select
//           value={selectedOption}
//           onChange={(e) => {
//             setSelectedOption(e.target.value);
//             changeTextColor();
//           }}
//           className={`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
//             isOptionSelected ? 'text-black dark:text-white' : ''
//           }`}
//         >
//           <option value="" disabled className="text-body dark:text-bodydark">
//             Selecione
//           </option>
//           <option value="gpt" className="text-body dark:text-bodydark">
//             gpt
//           </option>
//           <option value="llama" className="text-body dark:text-bodydark">
//            llama
//           </option>
//           <option value="pilot" className="text-body dark:text-bodydark">
//             pilot
//           </option>
//         </select>

//         <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
//           <svg
//             className="fill-current"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <g opacity="0.8">
//               <path
//                 fillRule="evenodd"
//                 clipRule="evenodd"
//                 d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
//                 fill=""
//               ></path>
//             </g>
//           </svg>
//         </span>
//       </div>
//     </div>
//   );
// };

// export default SelectGroupModelAgentProfile;


import React, { useState } from 'react';

// Definindo o tipo das props esperadas
interface SelectGroupModelAgentProfileProps {
  onChange: (model: string) => void; // Função esperada como prop
}

const SelectGroupModelAgentProfile: React.FC<SelectGroupModelAgentProfileProps> = ({ onChange }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedOption(value);
    setIsOptionSelected(true);
    onChange(value); // Chamada da função passada via prop
  };

  return (
    <div className="mb-5.5">
      <label className="mb-2.5 block text-azul_medio dark:text-white">
        Modelo <span className="text-meta-1">*</span>
      </label>
      <p>Selecione o modelo de IA que o agente deve utilizar em suas respostas.</p>

      <div className="relative z-20 bg-transparent dark:bg-form-input">
        <select
          value={selectedOption}
          onChange={handleSelectChange} // Evento de mudança
          className={`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
            isOptionSelected ? 'text-black dark:text-white' : ''
          }`}
        >
          <option value="" disabled>
            Selecione
          </option>
          <option value="1">gpt</option>
          <option value="2">llama</option>
          <option value="3">pilot</option>
        </select>
      </div>
    </div>
  );
};

export default SelectGroupModelAgentProfile;
