import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DropdownDefault from '../../components/Dropdowns/DropdownDefault';
import DefaultLayout from '../../layout/DefaultLayout';
import UserOne from '../../images/user/user-01.png';
import { Link } from 'react-router-dom';

const Training: React.FC = () => {
  const [messages, setMessages] = useState<any[]>([]); // Armazena as mensagens
  const [newMessage, setNewMessage] = useState(''); // Mensagem atual
  const [isLoading, setIsLoading] = useState(false); // Indica carregamento
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Armazena o arquivo selecionado
  const [imageMessage, setImageMessage] = useState(''); // Mensagem de confirmação para o arquivo selecionado

  // Carregar mensagens iniciais da API
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('/api/messages'); // Substitua pela URL real
        setMessages(response.data);
      } catch (error) {
        console.error('Erro ao carregar mensagens:', error);
      }
    };
    fetchMessages();
  }, []);

  // Função para enviar mensagem
  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() && !selectedFile) return;

    setIsLoading(true);

    try {
      const formData = new FormData();
      if (newMessage.trim()) formData.append('content', newMessage);
      if (selectedFile) formData.append('file', selectedFile);

      const response = await axios.post('/api/messages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setMessages([...messages, response.data]); // Atualiza mensagens
      setNewMessage(''); // Limpa o campo de texto
      setSelectedFile(null); // Limpa o arquivo selecionado
      setImageMessage(''); // Limpa a mensagem de confirmação
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Função chamada quando um arquivo é selecionado
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile(file);
    if (file) {
      setImageMessage('Imagem selecionada com sucesso!');
    } else {
      setImageMessage('');
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Training" />

      <div className="h-[calc(100vh-186px)] overflow-hidden sm:h-[calc(100vh-174px)]">
        <div className="h-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:flex">

          {/* Chat Box */}
          <div className="flex h-full flex-col border-l border-stroke dark:border-strokedark xl:w-full">
            <div className="sticky flex items-center justify-between border-b border-stroke px-6 py-4.5 dark:border-strokedark">
              <div className="flex items-center">
                <div className="mr-4.5 h-13 w-full max-w-13 overflow-hidden rounded-full">
                  <img
                    src={UserOne}
                    alt="avatar"
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div>
                  <h5 className="font-medium text-black dark:text-white">
                    Henry Dholi
                  </h5>
                  <p className="text-sm">Reply to message</p>
                </div>
              </div>
              <div>
                <DropdownDefault />
              </div>
            </div>

            {/* Mensagens */}
            <div className="no-scrollbar max-h-full space-y-3.5 overflow-auto px-6 py-7.5">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`max-w-125 ${message.isOwn ? 'ml-auto' : ''}`}
                >
                  {!message.isOwn && (
                    <p className="mb-2.5 text-sm font-medium">
                      {message.sender}
                    </p>
                  )}
                  <div
                    className={`mb-2.5 rounded-2xl py-3 px-5 ${
                      message.isOwn
                        ? 'bg-primary text-white rounded-br-none'
                        : 'bg-gray rounded-tl-none'
                    }`}
                  >
                    {message.content && <p>{message.content}</p>}
                    {message.image && (
                      <img
                        src={message.image}
                        alt="Attachment"
                        className="mt-2 max-w-full rounded-md"
                      />
                    )}
                  </div>
                  <p className={`text-xs ${message.isOwn ? 'text-right' : ''}`}>
                    {message.time}
                  </p>
                </div>
              ))}
            </div>

            {/* Campo de Enviar Mensagem */}
            <div className="sticky bottom-0 border-t border-stroke bg-white py-5 px-6 dark:border-strokedark dark:bg-boxdark">
              <form
                className="flex items-center justify-between space-x-4.5"
                onSubmit={handleSendMessage}
              >
                <div className="relative w-full">
                  <input
                    type="text"
                    placeholder="Type something here"
                    className="h-13 w-full rounded-md border border-stroke bg-gray pl-5 pr-19 text-black placeholder-body outline-none focus:border-primary dark:border-strokedark dark:bg-boxdark-2 dark:text-white"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    disabled={isLoading}
                  />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                  id="file-upload"
                />
                <label
                  htmlFor="file-upload"
                  className="h-13 px-4 cursor-pointer rounded-md border bg-gray text-black hover:bg-gray-200 dark:bg-boxdark-2 dark:text-white"
                >
                  📷
                </label>
                <button
                  type="submit"
                  className="h-13 px-6 bg-primary text-white rounded-md hover:bg-primary-dark"
                  disabled={isLoading}
                >
                  {isLoading ? 'Sending...' : 'Send'}
                </button>
              </form>

              {/* Mensagem de Confirmação */}
              {imageMessage && (
                <p className="mt-2 text-sm text-green-500">{imageMessage}</p>
              )}
            </div>
          </div>
          
        </div>

      </div>

      <div className="mt-4 flex justify-start">
          <Link
            to="/"
            className="rounded-md bg-virtuspurple px-9 py-3 font-medium text-white hover:bg-opacity-90"
          >
            Finalizar e utilizar
          </Link>
        </div>
    </DefaultLayout>
  );
};

export default Training;
