// import React, { useState, useEffect, useRef } from 'react';
// import SelectGroupFonte from '../Forms/SelectGroup/SelectGroupFonte';
// import DataTableQuestions from '../DataTables/DataTableQuestions';
// import ModalNQ from './ModalNQ';
// import FileDropOne from '../Forms/FileDropOne';

// const ModalAgentKn: React.FC = () => {
//   const [modalOpen, setModalOpen] = useState(false);
//   const [part, setPart] = useState(1); // Controla a parte ativa do modal
//   const [isOptionSelected, setIsOptionSelected] = useState(false); // Controla se uma opção foi selecionada
//   const [selectedOption, setSelectedOption] = useState<string | null>(null); // Armazena a opção selecionada

//   const trigger = useRef<HTMLButtonElement>(null);
//   const modal = useRef<HTMLDivElement>(null);

//   // Fecha o modal ao pressionar a tecla esc
//   useEffect(() => {
//     const keyHandler = ({ keyCode }: KeyboardEvent) => {
//       if (!modalOpen || keyCode !== 27) return; // Fecha apenas se o modal estiver aberto
//       closeModal();
//     };
//     document.addEventListener('keydown', keyHandler);
//     return () => document.removeEventListener('keydown', keyHandler);
//   }, [modalOpen]);

//   // Abre o modal sem recarregar a página
//   const openModal = (e: React.MouseEvent) => {
//     e.preventDefault();
//     setModalOpen(true);
//   };

//   // Fecha o modal e reseta estados
//   const closeModal = () => {
//     setModalOpen(false);
//     setPart(1);
//     setSelectedOption(null);
//     setIsOptionSelected(false);
//   };

//   // Atualiza o estado quando uma opção é selecionada
//   const handleSelectChange = (option: string) => {
//     setSelectedOption(option);
//     setIsOptionSelected(!!option);
//   };

//   // Avança para a segunda parte ao clicar em "Próximo"
//   const handleNextClick = (e: React.MouseEvent) => {
//     e.preventDefault();
//     if (isOptionSelected) {
//       setPart(2);
//     }
//   };

//   // Renderiza a segunda etapa com base na opção selecionada
//   const renderSecondPart = () => {
//     let title = '';
//     let description = '';
//     let content = null; // Para armazenar o conteúdo da etapa 2

//     switch (selectedOption) {
//       case 'URL':
//         title = 'Etapa 2 de 2 - URL';
//         description = 'Insira os sites que o agente deve consultar os dados.';
//         content = (
//           <div className="flex items-center">
//             <input
//               type="url"
//               placeholder="Insira a URL"
//               className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary"
//             />
//             <button
//               className="ml-2 rounded border border-primary bg-primary p-3 text-white hover:bg-opacity-90"
//               type="button"
//             >
//               Carregar
//             </button>
//           </div>
//         );
//         break;
//       case 'Banco de Dados':
//         title = 'Etapa 2 de 2 - Banco de Dados';
//         description = 'Conecte o agente a um banco de dados para ele consultar em tempo real suas informações.';
//         content = (
//           <div className="flex items-center">
//             <input
//               type="text"
//               placeholder="Insira"
//               className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary"
//             />
//             <button
//               className="ml-2 rounded border border-primary bg-primary p-3 text-white hover:bg-opacity-90"
//               type="button"
//             >
//               Conectar
//             </button>
//           </div>
//         );
//         break;
//       case 'API':
//         title = 'Etapa 2 de 2 - API';
//         description = 'Insira os sites que o agente deve consultar os dados.';
//         content = (
//           <div className="flex flex-col items-start w-full">
//   <input
//     type="text"
//     placeholder="Insira"
//     className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary"
//   />
//   <button
//     className="mt-4 rounded border border-primary bg-primary px-5 py-3 text-white hover:bg-opacity-90"
//     type="button"
//   >
//     Nova chave
//   </button>
// </div>
//         );
//         break;
//       case 'Webhook':
//         title = 'Etapa 2 de 2 - Webhook';
//         description = 'Insira os sites que o agente consultará os dados.';
//         content = (
//           <div className="flex flex-col items-start w-full">
//             <div className="w-full">
//               <h3>Parâmetros</h3>
//                 <textarea
//                   rows={6}
//                   placeholder="Insira parâmetros"
//                   className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-4 text-black outline-none"
//                   style={{ resize: 'none', boxSizing: 'border-box', height: '120px' }}
//                 ></textarea>
//             </div>
//             <div className="w-full mt-4">
//               <h3>Cabeçalho</h3>
//               <textarea
//                 rows={6}
//                 placeholder="Insira cabeçalho"
//                 className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-4 text-black outline-none"
//                 style={{ resize: 'none', boxSizing: 'border-box', height: '120px' }}
//               ></textarea>
//             </div>
//         </div>
//         );
//         break;
//       case 'Arquivo':
//         title = 'Etapa 2 de 2 - Arquivo';
//         description = 'Faça o upload de arquivos que ajudem a descrever os produtos ou serviços.';
//         content = (
//           <div className="flex flex-col gap-5.5 p-6.5">
//             {/* <div>
//               <input
//                 type="file"
//                 className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:py-3 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"                />
//             </div> */}
//             <FileDropOne />
//           </div>
//         );
//         break;
//         case 'Prompt':
//         title = 'Etapa 2 de 2 - URL';
//         description = 'Descreva conteúdos sobre os conhecimentos do agente.';
//         content = (
//           <div className="flex items-center">
//            <div className="w-full mt-4">
//               <textarea
//                 rows={6}
//                 placeholder="Insira"
//                 className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-4 text-black outline-none"
//                 style={{ resize: 'none', boxSizing: 'border-box', height: '120px' }}
//               ></textarea>
//             </div>
//           </div>
//         );
//         break;
//       case 'Perguntas e respostas':
//         title = 'Etapa 2 de 2 - Perguntas e respostas';
//         description = 'Defina perguntas e respostas paronizadas que o agente deve consultar.';
//         content = (
//           <div>
//           <div>
//             <DataTableQuestions />
//           </div>
          
//           <div className="mt-4 flex justify-between gap-4">
            
//             <ModalNQ />
//           </div>
//           </div>
//         );
        
//         <DataTableQuestions/>
//         break;
//       default:
//         title = 'Etapa 2 de 2';
//         description = 'Insira as informações necessárias.';
//     }

//     return (
//       <>
//         <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
//           {title}
//         </h3>
//         <p>{description}</p>
//         {content}
//         <div className="mt-4 flex justify-between gap-4">
//           <button
//             onClick={closeModal}
//             className="rounded border border-stroke bg-gray px-5 py-2 text-black hover:bg-meta-1 hover:text-white"
//           >
//             Cancelar
//           </button>
//           <button
//             onClick={() => setPart(1)}
//             className="rounded border border-primary bg-primary px-5 py-2 text-white hover:bg-opacity-90"
//           >
//             Anterior
//           </button>
//           <button
//             onClick={closeModal}
//             className="rounded border border-primary bg-primary px-5 py-2 text-white hover:bg-opacity-90"
//           >
//             Criar
//           </button>
//         </div>
//       </>
//     );
//   };

//   return (
//     <div>
//       <button
//         ref={trigger}
//         onClick={openModal}
//         className="rounded-md bg-primary px-9 py-3 font-medium text-white hover:bg-opacity-90"
//         type="button"
//       >
//         Criar Conhecimento
//       </button>
//       {modalOpen && (
//         <div
//           className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5"
//         >
//           <div
//             ref={modal}
//             className="w-full max-w-3xl rounded-lg bg-white p-8 text-center dark:bg-boxdark"
//           >
//             {part === 1 ? (
//               <>
//                 <h3 className="pb-2 text-xl font-bold text-black sm:text-2xl">
//                   Etapa 1 de 2
//                 </h3>
//                 <p>Defina o título e a fonte de dados do conhecimento.</p>
//                 <div>
//                   <label className="mb-3 block text-black">Título<span className="text-meta-1">*</span></label>
//                   <input
//                     type="text"
//                     placeholder="Insira"
//                     className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
//                     required
//                   />
//                 </div>
//                 <SelectGroupFonte onChange={handleSelectChange} />
//                 <div className="mt-4 flex justify-between gap-4">
//                   <button
//                     onClick={closeModal}
//                     className="rounded border border-stroke bg-gray px-5 py-2 text-black hover:bg-meta-1 hover:text-white"
//                   >
//                     Cancelar
//                   </button>
//                   <button
//                     onClick={handleNextClick}
//                     disabled={!isOptionSelected}
//                     className={`rounded bg-primary px-5 py-2 text-white ${
//                       isOptionSelected ? 'hover:bg-opacity-90' : 'opacity-50 cursor-not-allowed'
//                     }`}
//                   >
//                     Próximo
//                   </button>
//                 </div>
//               </>
//             ) : (
//               renderSecondPart()
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ModalAgentKn;


// import React, { useState, useEffect, useRef } from 'react';
// import SelectGroupFonte from '../Forms/SelectGroup/SelectGroupFonte';
// import DataTableQuestions from '../DataTables/DataTableQuestions';
// import ModalNQ from './ModalNQ';
// import FileDropOne from '../Forms/FileDropOne';

// const ModalAgentKn: React.FC = () => {
//   const [modalOpen, setModalOpen] = useState(false);
//   const [part, setPart] = useState(1); // Controla a parte ativa do modal
//   const [isOptionSelected, setIsOptionSelected] = useState(false); // Controla se uma opção foi selecionada
//   const [selectedOption, setSelectedOption] = useState<string | null>(null); // Armazena a opção selecionada

//   const trigger = useRef<HTMLButtonElement>(null);
//   const modal = useRef<HTMLDivElement>(null);

//   // Fecha o modal ao pressionar a tecla esc
//   useEffect(() => {
//     const keyHandler = ({ keyCode }: KeyboardEvent) => {
//       if (!modalOpen || keyCode !== 27) return; // Fecha apenas se o modal estiver aberto
//       closeModal();
//     };
//     document.addEventListener('keydown', keyHandler);
//     return () => document.removeEventListener('keydown', keyHandler);
//   }, [modalOpen]);

//   // Abre o modal sem recarregar a página
//   const openModal = (e: React.MouseEvent) => {
//     e.preventDefault();
//     setModalOpen(true);
//   };

//   // Fecha o modal e reseta estados
//   const closeModal = () => {
//     setModalOpen(false);
//     setPart(1);
//     setSelectedOption(null);
//     setIsOptionSelected(false);
//   };

//   // Atualiza o estado quando uma opção é selecionada
//   const handleSelectChange = (option: string) => {
//     setSelectedOption(option);
//     setIsOptionSelected(!!option);
//   };

//   // Avança para a segunda parte ao clicar em "Próximo"
//   const handleNextClick = (e: React.MouseEvent) => {
//     e.preventDefault();
//     if (isOptionSelected) {
//       setPart(2);
//     }
//   };

//   // Renderiza a segunda etapa com base na opção selecionada
//   const renderSecondPart = () => {
//     let title = '';
//     let description = '';
//     let content = null; // Para armazenar o conteúdo da etapa 2

//     switch (selectedOption) {
//       case 'URL':
//         title = 'Etapa 2 de 2 - URL';
//         description = 'Insira os sites que o agente deve consultar os dados.';
//         content = (
//           <div className="flex items-center">
//             <input
//               type="url"
//               placeholder="Insira a URL"
//               className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary"
//             />
//             <button
//               className="ml-2 rounded border border-primary bg-primary p-3 text-white hover:bg-opacity-90"
//               type="button"
//             >
//               Carregar
//             </button>
//           </div>
//         );
//         break;
//       case 'Banco de Dados':
//         title = 'Etapa 2 de 2 - Banco de Dados';
//         description = 'Conecte o agente a um banco de dados para ele consultar em tempo real suas informações.';
//         content = (
//           <div className="flex items-center">
//             <input
//               type="text"
//               placeholder="Insira"
//               className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary"
//             />
//             <button
//               className="ml-2 rounded border border-primary bg-primary p-3 text-white hover:bg-opacity-90"
//               type="button"
//             >
//               Conectar
//             </button>
//           </div>
//         );
//         break;
//       case 'API':
//         title = 'Etapa 2 de 2 - API';
//         description = 'Insira os sites que o agente deve consultar os dados.';
//         content = (
//           <div className="flex flex-col items-start w-full">
//             <input
//               type="text"
//               placeholder="Insira"
//               className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary"
//             />
//             <button
//               className="mt-4 rounded border border-primary bg-primary px-5 py-3 text-white hover:bg-opacity-90"
//               type="button"
//             >
//               Nova chave
//             </button>
//           </div>
//         );
//         break;
//       case 'Webhook':
//         title = 'Etapa 2 de 2 - Webhook';
//         description = 'Insira os sites que o agente consultará os dados.';
//         content = (
//           <div className="flex flex-col items-start w-full">
//             <div className="w-full">
//               <h3>Parâmetros</h3>
//               <textarea
//                 rows={6}
//                 placeholder="Insira parâmetros"
//                 className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-4 text-black outline-none"
//                 style={{ resize: 'none', boxSizing: 'border-box', height: '120px' }}
//               ></textarea>
//             </div>
//             <div className="w-full mt-4">
//               <h3>Cabeçalho</h3>
//               <textarea
//                 rows={6}
//                 placeholder="Insira cabeçalho"
//                 className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-4 text-black outline-none"
//                 style={{ resize: 'none', boxSizing: 'border-box', height: '120px' }}
//               ></textarea>
//             </div>
//           </div>
//         );
//         break;
//       case 'Arquivo':
//         title = 'Etapa 2 de 2 - Arquivo';
//         description = 'Faça o upload de arquivos que ajudem a descrever os produtos ou serviços.';
//         content = (
//           <div className="flex flex-col gap-5.5 p-6.5">
//             <FileDropOne />
//           </div>
//         );
//         break;
//       case 'Prompt':
//         title = 'Etapa 2 de 2 - URL';
//         description = 'Descreva conteúdos sobre os conhecimentos do agente.';
//         content = (
//           <div className="flex items-center">
//             <div className="w-full mt-4">
//               <textarea
//                 rows={6}
//                 placeholder="Insira"
//                 className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-4 text-black outline-none"
//                 style={{ resize: 'none', boxSizing: 'border-box', height: '120px' }}
//               ></textarea>
//             </div>
//           </div>
//         );
//         break;
//       case 'Perguntas e respostas':
//         title = 'Etapa 2 de 2 - Perguntas e respostas';
//         description = 'Defina perguntas e respostas paronizadas que o agente deve consultar.';
//         content = (
//           <div>
//             <div>
//               <DataTableQuestions />
//             </div>

//             <div className="mt-4 flex justify-between gap-4">
//               <ModalNQ />
//             </div>
//           </div>
//         );
//         break;
//       default:
//         title = 'Etapa 2 de 2';
//         description = 'Insira as informações necessárias.';
//     }

//     return (
//       <>
//         <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
//           {title}
//         </h3>
//         <p>{description}</p>
//         {content}
//         <div className="mt-4 flex justify-between gap-4">
//           <button
//             onClick={closeModal}
//             className="rounded border border-stroke bg-gray px-5 py-2 text-black hover:bg-meta-1 hover:text-white"
//           >
//             Cancelar
//           </button>
//           <button
//             onClick={() => setPart(1)}
//             className="rounded border border-primary bg-primary px-5 py-2 text-white hover:bg-opacity-90"
//           >
//             Anterior
//           </button>
//           <button
//             onClick={closeModal}
//             className="rounded border border-primary bg-primary px-5 py-2 text-white hover:bg-opacity-90"
//           >
//             Criar
//           </button>
//         </div>
//       </>
//     );
//   };

//   return (
//     <div>
//       <button
//         ref={trigger}
//         onClick={openModal}
//         className="rounded-md bg-virtuspurple px-9 py-3 font-medium text-white hover:bg-opacity-90"
//         type="button"
//       >
//         Criar Conhecimento
//       </button>
//       {modalOpen && (
//         <div
//           className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5"
//         >
//           <div
//             ref={modal}
//             className="w-full max-w-3xl rounded-lg bg-white p-8 text-center dark:bg-boxdark"
//           >
//             {part === 1 ? (
//               <>
//                 <h3 className="pb-2 text-xl font-bold text-black sm:text-2xl">
//                   Etapa 1 de 2
//                 </h3>
//                 <p>Defina o título e a fonte de dados do conhecimento.</p>
//                 <div>
//                   <label className="mb-3 block text-black">Título<span className="text-meta-1">*</span></label>
//                   <input
//                     type="text"
//                     placeholder="Insira"
//                     className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
//                     required
//                   />
//                 </div>
//                 <SelectGroupFonte onChange={handleSelectChange} />
//                 <div className="mt-4 flex justify-between gap-4">
//                   <button
//                     onClick={closeModal}
//                     className="rounded border border-stroke bg-gray px-5 py-2 text-black hover:bg-meta-1 hover:text-white"
//                   >
//                     Cancelar
//                   </button>
//                   <button
//                     onClick={handleNextClick}
//                     disabled={!isOptionSelected}
//                     className={`rounded bg-primary px-5 py-2 text-white ${
//                       isOptionSelected ? 'hover:bg-opacity-90' : 'opacity-50 cursor-not-allowed'
//                     }`}
//                   >
//                     Próximo
//                   </button>
//                 </div>
//               </>
//             ) : (
//               renderSecondPart()
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ModalAgentKn;

// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import SelectGroupFonte from '../Forms/SelectGroup/SelectGroupFonte';
// import DataTableQuestions from '../DataTables/DataTableQuestions';
// import ModalNQ from './ModalNQ';
// import FileDropOne from '../Forms/FileDropOne';

// const ModalAgentKn: React.FC = () => {
//   const [modalOpen, setModalOpen] = useState(false);
//   const [part, setPart] = useState(1); // Controla a parte ativa do modal
//   const [isOptionSelected, setIsOptionSelected] = useState(false); // Controla se uma opção foi selecionada
//   const [selectedOption, setSelectedOption] = useState<string | null>(null); // Armazena a opção selecionada
//   const [inputValue, setInputValue] = useState(''); // Armazena o valor do input do usuário
//   const [loading, setLoading] = useState(false); // Controla o estado de carregamento
//   const [errorMessage, setErrorMessage] = useState(''); // Armazena mensagens de erro da API

//   const trigger = useRef<HTMLButtonElement>(null);
//   const modal = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     const keyHandler = ({ keyCode }: KeyboardEvent) => {
//       if (!modalOpen || keyCode !== 27) return;
//       closeModal();
//     };
//     document.addEventListener('keydown', keyHandler);
//     return () => document.removeEventListener('keydown', keyHandler);
//   }, [modalOpen]);

//   const openModal = (e: React.MouseEvent) => {
//     e.preventDefault();
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//     setPart(1);
//     setSelectedOption(null);
//     setIsOptionSelected(false);
//     setInputValue('');
//     setErrorMessage('');
//   };

//   const handleSelectChange = (option: string) => {
//     setSelectedOption(option);
//     setIsOptionSelected(!!option);
//   };

//   const handleNextClick = (e: React.MouseEvent) => {
//     e.preventDefault();
//     if (isOptionSelected) {
//       setPart(2);
//     }
//   };

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     setInputValue(e.target.value);
//   };

//   const handleSubmit = async () => {
//     if (!selectedOption || !inputValue) {
//       setErrorMessage('Preencha todas as informações necessárias.');
//       return;
//     }

//     setLoading(true);
//     setErrorMessage('');

//     try {
//       let endpoint = '';
//       const data = { option: selectedOption, value: inputValue };

//       switch (selectedOption) {
//         case 'URL':
//           endpoint = '/api/url';
//           break;
//         case 'Banco de Dados':
//           endpoint = '/api/database';
//           break;
//         case 'API':
//           endpoint = '/api/api';
//           break;
//         case 'Webhook':
//           endpoint = '/api/webhook';
//           break;
//         case 'Arquivo':
//           endpoint = '/api/file';
//           break;
//         case 'Prompt':
//           endpoint = '/api/prompt';
//           break;
//         case 'Perguntas e respostas':
//           endpoint = '/api/faq';
//           break;
//         default:
//           throw new Error('Opção inválida');
//       }

//       const response = await axios.post(endpoint, data);
//       alert('Dados enviados com sucesso: ' + response.data.message);
//       closeModal();
//     } catch (error: any) {
//       setErrorMessage(error.response?.data?.message || 'Erro ao enviar dados.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const renderSecondPart = () => {
//     return (
//       <>
//         <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
//           Etapa 2 de 2 - {selectedOption}
//         </h3>
//         <p>Insira as informações necessárias.</p>
//         <div>
//           <input
//             type={selectedOption === 'URL' ? 'url' : 'text'}
//             placeholder={`Insira ${selectedOption}`}
//             className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
//             value={inputValue}
//             onChange={handleInputChange}
//           />
//         </div>
//         {errorMessage && <p className="text-red-500">{errorMessage}</p>}
//         <div className="mt-4 flex justify-between gap-4">
//           <button onClick={closeModal} className="rounded border border-gray px-5 py-2">
//             Cancelar
//           </button>
//           <button onClick={() => setPart(1)} className="rounded border border-primary px-5 py-2">
//             Anterior
//           </button>
//           <button
//             onClick={handleSubmit}
//             className="rounded border border-primary px-5 py-2"
//             disabled={loading}
//           >
//             {loading ? 'Enviando...' : 'Criar'}
//           </button>
//         </div>
//       </>
//     );
//   };

//   return (
//     <div>
//       <button
//         ref={trigger}
//         onClick={openModal}
//         className="rounded-md bg-primary px-9 py-3 font-medium text-white"
//       >
//         Criar Conhecimento
//       </button>
//       {modalOpen && (
//         <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/50 px-4">
//           <div ref={modal} className="w-full max-w-3xl bg-white p-8">
//             {part === 1 ? (
//               <>
//                 <h3 className="pb-2 text-xl font-bold">Etapa 1 de 2</h3>
//                 <p>Defina o título e a fonte de dados do conhecimento.</p>
//                 <div>
//                   <label className="mb-3 block">
//                     Título<span className="text-red-500">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Insira o título"
//                     className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
//                     required
//                   />
//                 </div>
//                 <SelectGroupFonte onChange={handleSelectChange} />
//                 <button
//                   onClick={handleNextClick}
//                   className="mt-4 rounded border border-primary px-5 py-2"
//                   disabled={!isOptionSelected}
//                 >
//                   Próximo
//                 </button>
//               </>
//             ) : (
//               renderSecondPart()
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ModalAgentKn;

///////

// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import SelectGroupFonte from '../Forms/SelectGroup/SelectGroupFonte';
// import DataTableQuestions from '../DataTables/DataTableQuestions';
// import ModalNQ from './ModalNQ';
// import FileDropOne from '../Forms/FileDropOne';

// const ModalAgentKn: React.FC = () => {
//   const [modalOpen, setModalOpen] = useState(false);
//   const [part, setPart] = useState(1); // Controla a parte ativa do modal
//   const [isOptionSelected, setIsOptionSelected] = useState(false); // Controla se uma opção foi selecionada
//   const [selectedOption, setSelectedOption] = useState<string | null>(null); // Armazena a opção selecionada
//   const [inputValue, setInputValue] = useState(''); // Armazena o valor do input do usuário
//   const [loading, setLoading] = useState(false); // Controla o estado de carregamento
//   const [errorMessage, setErrorMessage] = useState(''); // Armazena mensagens de erro da API

//   const trigger = useRef<HTMLButtonElement>(null);
//   const modal = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     const keyHandler = ({ keyCode }: KeyboardEvent) => {
//       if (!modalOpen || keyCode !== 27) return;
//       closeModal();
//     };
//     document.addEventListener('keydown', keyHandler);
//     return () => document.removeEventListener('keydown', keyHandler);
//   }, [modalOpen]);

//   const openModal = (e: React.MouseEvent) => {
//     e.preventDefault();
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//     setPart(1);
//     setSelectedOption(null);
//     setIsOptionSelected(false);
//     setInputValue('');
//     setErrorMessage('');
//   };

//   const handleSelectChange = (option: string) => {
//     setSelectedOption(option);
//     setIsOptionSelected(!!option);
//   };

//   const handleNextClick = (e: React.MouseEvent) => {
//     e.preventDefault();
//     if (isOptionSelected) {
//       setPart(2);
//     }
//   };

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     setInputValue(e.target.value);
//   };

//   const handleSubmit = async () => {
//     if (!selectedOption || !inputValue) {
//       setErrorMessage('Preencha todas as informações necessárias.');
//       return;
//     }

//     setLoading(true);
//     setErrorMessage('');

//     try {
//       let endpoint = '';
//       const data = { option: selectedOption, value: inputValue };

//       switch (selectedOption) {
//         case 'URL':
//           endpoint = '/api/url';
//           break;
//         case 'Banco de Dados':
//           endpoint = '/api/database';
//           break;
//         case 'API':
//           endpoint = '/api/api';
//           break;
//         case 'Arquivo':
//           endpoint = '/api/file';
//           break;
//         case 'Prompt':
//           endpoint = '/api/prompt';
//           break;
//         default:
//           throw new Error('Opção inválida');
//       }

//       const response = await axios.post(endpoint, data);
//       alert('Dados enviados com sucesso: ' + response.data.message);
//       closeModal();
//     } catch (error: any) {
//       setErrorMessage(error.response?.data?.message || 'Erro ao enviar dados.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const renderSecondPart = () => {
//     return (
//       <>
//         <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
//           Etapa 2 de 2 - {selectedOption}
//         </h3>
//         <p>Insira as informações necessárias.</p>
//         <div>
//           <input
//             type={selectedOption === 'URL' ? 'url' : 'text'}
//             placeholder={`Insira ${selectedOption}`}
//             className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
//             value={inputValue}
//             onChange={handleInputChange}
//           />
//         </div>
//         {errorMessage && <p className="text-red-500">{errorMessage}</p>}
//         <div className="mt-4 flex justify-between gap-4">
//           <button onClick={closeModal} className="rounded border border-gray px-5 py-2">
//             Cancelar
//           </button>
//           <button onClick={() => setPart(1)} className="rounded border border-primary px-5 py-2">
//             Anterior
//           </button>
//           <button
//             onClick={handleSubmit}
//             className="rounded border border-primary px-5 py-2"
//             disabled={loading}
//           >
//             {loading ? 'Enviando...' : 'Criar'}
//           </button>
//         </div>
//       </>
//     );
//   };

//   return (
//     <div>
//       <button
//         ref={trigger}
//         onClick={openModal}
//         className="rounded-md bg-primary px-9 py-3 font-medium text-white"
//       >
//         Criar Conhecimento
//       </button>
//       {modalOpen && (
//         <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/50 px-4">
//           <div ref={modal} className="w-full max-w-3xl bg-white p-8">
//             {part === 1 ? (
//               <>
//                 <h3 className="pb-2 text-xl font-bold">Etapa 1 de 2</h3>
//                 <p>Defina o título e a fonte de dados do conhecimento.</p>
//                 <div>
//                   <label className="mb-3 block">
//                     Título<span className="text-red-500">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Insira o título"
//                     className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
//                     required
//                   />
//                 </div>
//                 <SelectGroupFonte onChange={handleSelectChange} />
//                 <div className="mt-4 flex justify-between gap-4">
//                   <button onClick={closeModal} className="rounded border border-gray px-5 py-2">
//                     Cancelar
//                   </button>
//                   <button
//                     onClick={handleNextClick}
//                     className="rounded border border-primary px-5 py-2"
//                     disabled={!isOptionSelected}
//                   >
//                     Próximo
//                   </button>
//                 </div>
//               </>
//             ) : (
//               renderSecondPart()
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ModalAgentKn;
///////////////

// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import SelectGroupFonte from '../Forms/SelectGroup/SelectGroupFonte';
// import DataTableQuestions from '../DataTables/DataTableQuestions';
// import ModalNQ from './ModalNQ';
// import FileDropOne from '../Forms/FileDropOne';

// const ModalAgentKn: React.FC = () => {
//   const [modalOpen, setModalOpen] = useState(false);
//   const [part, setPart] = useState(1); // Controla a parte ativa do modal
//   const [isOptionSelected, setIsOptionSelected] = useState(false); // Controla se uma opção foi selecionada
//   const [selectedOption, setSelectedOption] = useState<string | null>(null); // Armazena a opção selecionada
//   const [inputValue, setInputValue] = useState(''); // Armazena o valor do input do usuário
//   const [loading, setLoading] = useState(false); // Controla o estado de carregamento
//   const [errorMessage, setErrorMessage] = useState(''); // Armazena mensagens de erro da API

//   const trigger = useRef<HTMLButtonElement>(null);
//   const modal = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     const keyHandler = ({ keyCode }: KeyboardEvent) => {
//       if (!modalOpen || keyCode !== 27) return;
//       closeModal();
//     };
//     document.addEventListener('keydown', keyHandler);
//     return () => document.removeEventListener('keydown', keyHandler);
//   }, [modalOpen]);

//   const openModal = (e: React.MouseEvent) => {
//     e.preventDefault();
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//     setPart(1);
//     setSelectedOption(null);
//     setIsOptionSelected(false);
//     setInputValue('');
//     setErrorMessage('');
//   };

//   const handleSelectChange = (option: string) => {
//     setSelectedOption(option);
//     setIsOptionSelected(!!option);
//   };

//   const handleNextClick = (e: React.MouseEvent) => {
//     e.preventDefault();
//     if (isOptionSelected) {
//       setPart(2);
//     }
//   };

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     setInputValue(e.target.value);
//   };

//   const handleSubmit = async () => {
//     if (!selectedOption || !inputValue) {
//       setErrorMessage('Preencha todas as informações necessárias.');
//       return;
//     }

//     setLoading(true);
//     setErrorMessage('');

//     try {
//       let endpoint = '';
//       const data = { option: selectedOption, value: inputValue };

//       switch (selectedOption) {
//         case 'URL':
//           endpoint = '/api/url';
//           break;
//         case 'Banco de Dados':
//           endpoint = '/api/database';
//           break;
//         case 'API':
//           endpoint = '/api/api';
//           break;
//         case 'Arquivo':
//           endpoint = '/api/file';
//           break;
//         case 'Prompt':
//           endpoint = '/api/prompt';
//           break;
//         default:
//           throw new Error('Opção inválida');
//       }

//       const response = await axios.post(endpoint, data);
//       alert('Dados enviados com sucesso: ' + response.data.message);
//       closeModal();
//     } catch (error: any) {
//       setErrorMessage(error.response?.data?.message || 'Erro ao enviar dados.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const renderSecondPart = () => {
//     return (
//       <>
//         <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
//           Etapa 2 de 2 - {selectedOption}
//         </h3>
//         <p>Insira as informações necessárias.</p>
//         <div>
//           <input
//             type={selectedOption === 'URL' ? 'url' : selectedOption === 'Arquivo' ? 'file' : 'text'}
//             placeholder={`Insira ${selectedOption}`}
//             className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
//             value={selectedOption === 'Arquivo' ? undefined : inputValue} // Limpa o valor para 'file'
//             onChange={selectedOption === 'Arquivo' ? undefined : handleInputChange} // Remove onChange para 'file'
//           />
//         </div>
//         {errorMessage && <p className="text-red-500">{errorMessage}</p>}
//         <div className="mt-4 flex justify-between gap-4">
//           <button onClick={closeModal} className="rounded border border-gray px-5 py-2">
//             Cancelar
//           </button>
//           <button onClick={() => setPart(1)} className="rounded border border-primary px-5 py-2">
//             Anterior
//           </button>
//           <button
//             onClick={handleSubmit}
//             className="rounded border border-primary px-5 py-2"
//             disabled={loading}
//           >
//             {loading ? 'Enviando...' : 'Criar'}
//           </button>
//         </div>
//       </>
//     );
//   };

//   return (
//     <div>
//       <button
//         ref={trigger}
//         onClick={openModal}
//         className="rounded-md bg-primary px-9 py-3 font-medium text-white"
//       >
//         Criar Conhecimento
//       </button>
//       {modalOpen && (
//         <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/50 px-4">
//           <div ref={modal} className="w-full max-w-3xl bg-white p-8">
//             {part === 1 ? (
//               <>
//                 <h3 className="pb-2 text-xl font-bold">Etapa 1 de 2</h3>
//                 <p>Defina o título e a fonte de dados do conhecimento.</p>
//                 <div>
//                   <label className="mb-3 block">
//                     Título<span className="text-red-500">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Insira o título"
//                     className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
//                     required
//                   />
//                 </div>
//                 <SelectGroupFonte onChange={handleSelectChange} />
//                 <div className="mt-4 flex justify-between gap-4">
//                   <button onClick={closeModal} className="rounded border border-gray px-5 py-2">
//                     Cancelar
//                   </button>
//                   <button
//                     onClick={handleNextClick}
//                     className="rounded border border-primary px-5 py-2"
//                     disabled={!isOptionSelected}
//                   >
//                     Próximo
//                   </button>
//                 </div>
//               </>
//             ) : (
//               renderSecondPart()
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ModalAgentKn;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SelectGroupFonte from '../Forms/SelectGroup/SelectGroupFonte';
// import DataTableQuestions from '../DataTables/DataTableQuestions';
// import ModalNQ from './ModalNQ';
// import FileDropOne from '../Forms/FileDropOne';

const ModalAgentKn: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [part, setPart] = useState(1); // Etapa ativa do modal
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState(''); // Valor do input de texto
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Arquivo selecionado
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const trigger = useRef<HTMLButtonElement>(null);
  const modal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!modalOpen || keyCode !== 27) return;
      closeModal();
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [modalOpen]);

  const openModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setPart(1);
    setSelectedOption(null);
    setIsOptionSelected(false);
    setInputValue('');
    setSelectedFile(null);
    setErrorMessage('');
  };

  const handleSelectChange = (option: string) => {
    setSelectedOption(option);
    setIsOptionSelected(!!option);
  };

  const handleNextClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isOptionSelected) {
      setPart(2);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (!selectedOption || (selectedOption !== '4' && !inputValue) || (selectedOption === '4' && !selectedFile)) {
      setErrorMessage('Preencha todas as informações necessárias.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      const formData = new FormData();
      let endpoint = '';

      switch (selectedOption) {
        case '1':
          endpoint = '/api/url';
          formData.append('url', inputValue);
          break;
        case '2':
          endpoint = '/api/database';
          formData.append('database', inputValue);
          break;
        case '3':
          endpoint = '/api/api';
          formData.append('api', inputValue);
          break;
        case '4':
          endpoint = '/api/file';
          formData.append('file', selectedFile as File);
          break;
        case '5':
          endpoint = '/api/prompt';
          formData.append('prompt', inputValue);
          break;
        default:
          throw new Error('Opção inválida');
      }

      const response = await axios.post(endpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      alert('Dados enviados com sucesso: ' + response.data.message);
      closeModal();
    } catch (error: any) {
      setErrorMessage(error.response?.data?.message || 'Erro ao enviar dados.');
    } finally {
      setLoading(false);
    }
  };

  const renderSecondPart = () => {
    return (
      <>
        <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
          Etapa 2 de 2 - {selectedOption}
        </h3>
        <p>Insira as informações necessárias.</p>
        <div>
          {selectedOption === '4' ? (
            <input
              type="file"
              className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
              onChange={handleFileChange}
            />
          ) : (
            <input
              type={selectedOption === '1' ? '1' : 'text'}
              placeholder={`Insira ${selectedOption}`}
              className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
              value={inputValue}
              onChange={handleInputChange}
            />
          )}
        </div>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        <div className="mt-4 flex justify-between gap-4">
          <button onClick={closeModal} className="rounded border border-gray px-5 py-2">
            Cancelar
          </button>
          <button onClick={() => setPart(1)} className="rounded border border-primary px-5 py-2">
            Anterior
          </button>
          <button
            onClick={handleSubmit}
            className="rounded border border-primary px-5 py-2"
            disabled={loading}
          >
            {loading ? 'Enviando...' : 'Criar'}
          </button>
        </div>
      </>
    );
  };

  return (
    <div>
      <button
        ref={trigger}
        onClick={openModal}
        className="rounded-md bg-primary px-9 py-3 font-medium text-white"
      >
        Criar Conhecimento
      </button>
      {modalOpen && (
        <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/50 px-4">
          <div ref={modal} className="w-full max-w-3xl bg-white p-8">
            {part === 1 ? (
              <>
                <h3 className="pb-2 text-xl font-bold">Etapa 1 de 2</h3>
                <p>Defina o título e a fonte de dados do conhecimento.</p>
                <div>
                  <label className="mb-3 block">
                    Título<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Insira o título"
                    className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
                    required
                  />
                </div>
                <SelectGroupFonte onChange={handleSelectChange} />
                <div className="mt-4 flex justify-between gap-4">
                  <button onClick={closeModal} className="rounded border border-gray px-5 py-2">
                    Cancelar
                  </button>
                  <button
                    onClick={handleNextClick}
                    className="rounded border border-primary px-5 py-2"
                    disabled={!isOptionSelected}
                  >
                    Próximo
                  </button>
                </div>
              </>
            ) : (
              renderSecondPart()
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalAgentKn;
