import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SelectGroupFonteHabilidade from '../Forms/SelectGroup/SelectGroupFonteHabilidade';

const ModalHabilidade: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [inputTitle, setInputTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const trigger = useRef<HTMLButtonElement>(null);
  const modal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!modalOpen || keyCode !== 27) return;
      closeModal();
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [modalOpen]);

  const openModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOption(null);
    setIsOptionSelected(false);
    setInputTitle('');
    setErrorMessage('');
  };

  const handleSelectChange = (option: string) => {
    setSelectedOption(option);
    setIsOptionSelected(!!option);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputTitle(e.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedOption || !inputTitle) {
      setErrorMessage('Preencha todas as informações necessárias.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      const data = { option: selectedOption, title: inputTitle };
      const endpoint = '/api/create-skill';

      const response = await axios.post(endpoint, data);
      alert('Habilidade criada com sucesso: ' + response.data.message);
      closeModal();
    } catch (error: any) {
      setErrorMessage(error.response?.data?.message || 'Erro ao criar habilidade.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        ref={trigger}
        onClick={openModal}
        className="rounded-md bg-primary px-9 py-3 font-medium text-white"
      >
        Criar Habilidade
      </button>
      {modalOpen && (
        <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/50 px-4">
          <div ref={modal} className="w-full max-w-3xl bg-white p-8">
            <h3 className="pb-2 text-xl font-bold">Criar Nova Habilidade</h3>
            <p>Defina o título e a fonte de dados do conhecimento.</p>
            <div>
              <label className="mb-3 block">
                Título<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Insira o título"
                className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
                value={inputTitle}
                onChange={handleInputChange}
                required
              />
            </div>
            <SelectGroupFonteHabilidade onChange={handleSelectChange} />
            {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
            <div className="mt-4 flex justify-between gap-4">
              <button onClick={closeModal} className="rounded border border-gray px-5 py-2">
                Cancelar
              </button>
              <button
                onClick={handleSubmit}
                className="rounded border border-primary px-5 py-2"
                disabled={!isOptionSelected || loading}
              >
                {loading ? 'Enviando...' : 'Criar'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalHabilidade;
