// import React, { useEffect, useMemo, useState } from 'react';
// import { Link } from 'react-router-dom';
// import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
// import CardsItemThree from '../../components/Cards/CardsItemThree';
// import SelectGroupAgentCategory from '../../components/Forms/SelectGroup/SelectGroupAgentCategoy';
// import SelectGroupAgentStatus from '../../components/Forms/SelectGroup/SelectGroupAgentStatus';
// import DefaultLayout from '../../layout/DefaultLayout';
// import { sendAuthorization } from '../../api/auth';
// import SelectGroupProjectBusiness from '../../components/Forms/SelectGroup/SelectGroupProjectBusiness';

// const BACKEND_BASE_URL = 'https://app-api.virtusautomation.com'; // Substitua pela URL base da sua API

// type Agent = {
//   name: string;
//   project: number;
//   category: string;
//   status: string;
//   description: string;
// };

// // Busca os agentes na API
// const fetchAgents = async (project: number): Promise<Agent[]> => {
//   const url = `${BACKEND_BASE_URL}/api/agents/?project=${project}`;

//   try {
//     const response = await sendAuthorization(url, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });

//     if (Array.isArray(response)) {
//       return response;
//     }

//     throw new Error('Resposta inesperada da API');
//   } catch (error) {
//     console.error('Erro ao buscar agentes:', error);
//     throw new Error('Erro ao buscar agentes');
//   }
// };

// // Função para obter o ID do projeto baseado no localStorage
// const getProjectIdFromLocalStorage = () => {
//   const selectedProjectId = localStorage.getItem('selectedProjectId');
//   const projectIdNumber = parseInt(selectedProjectId || '1', 10);

//   // Aplica as regras de substituição
//   if (projectIdNumber === 10) return 2;
//   if (projectIdNumber === 4) return 1;
//   return projectIdNumber; // Caso contrário, mantém o original
// };

// const Agents: React.FC = () => {
//   const [agentsData, setAgentsData] = useState<Agent[]>([]);
//   const [searchName, setSearchName] = useState('');
//   const [searchCategory, setSearchCategory] = useState('');
//   const [searchStatus, setSearchStatus] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [projectId, setProjectId] = useState(getProjectIdFromLocalStorage());
//   const itemsPerPage = 9;

//   // Atualiza o estado do projectId ao detectar mudanças no localStorage
//   useEffect(() => {
//     const handleStorageChange = () => {
//       const newProjectId = getProjectIdFromLocalStorage();
//       setProjectId(newProjectId);
//     };

//     // Verifica mudanças no localStorage a cada segundo (para mudanças locais)
//     const interval = setInterval(handleStorageChange, 1000);

//     // Cleanup do intervalo
//     return () => clearInterval(interval);
//   }, []);

//   // Busca os agentes sempre que o projectId mudar
//   useEffect(() => {
//     const getAgents = async () => {
//       try {
//         const agents = await fetchAgents(projectId);
//         setAgentsData(agents);
//       } catch (error) {
//         console.error('Erro ao carregar agentes:', error);
//       }
//     };

//     getAgents();
//   }, [projectId]); // Atualiza ao mudar o projeto

//   // Filtragem dos dados com base nos filtros aplicados
//   const filteredData = useMemo(() => {
//     return agentsData.filter(
//       (agent) =>
//         agent.name.toLowerCase().includes(searchName.toLowerCase()) &&
//         (searchCategory === '' || agent.category === searchCategory) &&
//         (searchStatus === '' || agent.status === searchStatus) &&
//         agent.project === projectId // Aplica o filtro baseado no projeto
//     );
//   }, [agentsData, searchName, searchCategory, searchStatus, projectId]);

//   // Paginação
//   const paginatedData = useMemo(() => {
//     return filteredData.slice(
//       (currentPage - 1) * itemsPerPage,
//       currentPage * itemsPerPage
//     );
//   }, [filteredData, currentPage, itemsPerPage]);

//   const totalPages = Math.ceil(filteredData.length / itemsPerPage);

//   const goToNextPage = () =>
//     setCurrentPage((prev) => Math.min(prev + 1, totalPages));
//   const goToPreviousPage = () =>
//     setCurrentPage((prev) => Math.max(prev - 1, 1));

//   return (
//     <DefaultLayout>
//       <Breadcrumb pageName="Agentes" />

//       <div className="mb-5 grid grid-cols-1 items-center gap-4 sm:grid-cols-3 lg:grid-cols-4">
//         <input
//           type="text"
//           placeholder="Pesquisar por nome"
//           value={searchName}
//           onChange={(e) => setSearchName(e.target.value)}
//           className="relative z-20 mt-4 w-full appearance-none rounded border border-stroke bg-white px-5 py-3 outline-none transition focus:border-primary active:border-primary dark:border-grafite_ dark:bg-form-input dark:focus:border-grafite_"
//         />

//         <SelectGroupAgentCategory
//           selectedOption={searchCategory}
//           onCategoryChange={(value: string) => setSearchCategory(value)}
//         />

//         <SelectGroupAgentStatus
//           selectedOption={searchStatus}
//           onStatusChange={(value: string) => setSearchStatus(value)}
//         />
        
//         <div className="mt-4 flex justify-start">
//           <Link
//             // to="/dashboard/Criação de Agente"
//             to="Agentes/Criação de Agente"
//             className="rounded-md bg-virtuspurple px-9 py-3 font-medium text-white hover:bg-opacity-90"
//           >
//             + Criar Agente
//           </Link>
//         </div>
//       </div>

//       <div className="grid grid-cols-1 gap-7.5 sm:grid-cols-2 xl:grid-cols-3">
//         {paginatedData.map((agent, index) => (
//           <CardsItemThree
//             key={index}
//             cardTitle={agent.name}
//             cardStatus={agent.status}
//             cardCategory={agent.category}
//             cardContent={agent.description}
//             cardProject={agent.project}
//           />
//         ))}
//       </div>

//       <div className="mt-5 flex items-center justify-between">
//         <button
//           onClick={goToPreviousPage}
//           disabled={currentPage === 1}
//           className="rounded-md border p-2"
//         >
//           Anterior
//         </button>
//         <span>
//           Página {currentPage} de {totalPages}
//         </span>
//         <button
//           onClick={goToNextPage}
//           disabled={currentPage === totalPages}
//           className="rounded-md border p-2"
//         >
//           Próxima
//         </button>
//       </div>
//     </DefaultLayout>
//   );
// };

// export default Agents;


import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import CardsItemThree from '../../components/Cards/CardsItemThree';
import SelectGroupAgentCategory from '../../components/Forms/SelectGroup/SelectGroupAgentCategoy';
import SelectGroupAgentStatus from '../../components/Forms/SelectGroup/SelectGroupAgentStatus';
import DefaultLayout from '../../layout/DefaultLayout';
import { sendAuthorization } from '../../api/auth';
import SelectGroupProjectBusiness from '../../components/Forms/SelectGroup/SelectGroupProjectBusiness';

const BACKEND_BASE_URL = 'https://app-api.virtusautomation.com'; // Substitua pela URL base da sua API

type Agent = {
  name: string;
  project: number;
  category: string;
  status: string;
  description: string;
};

// Busca os agentes na API
const fetchAgents = async (project: number): Promise<Agent[]> => {
  const url = `${BACKEND_BASE_URL}/api/agents/?project=${project}`;

  try {
    const response = await sendAuthorization(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (Array.isArray(response)) {
      return response;
    }

    throw new Error('Resposta inesperada da API');
  } catch (error) {
    console.error('Erro ao buscar agentes:', error);
    throw new Error('Erro ao buscar agentes');
  }
};

// Função para obter o ID do projeto baseado no localStorage
const getProjectIdFromLocalStorage = () => {
  const selectedProjectId = localStorage.getItem('selectedProjectId');
  const projectIdNumber = parseInt(selectedProjectId || '1', 10);

  // Aplica as regras de substituição
  if (projectIdNumber === 10) return 2;
  if (projectIdNumber === 4) return 1;
  return projectIdNumber; // Caso contrário, mantém o original
};

const Agents: React.FC = () => {
  const [agentsData, setAgentsData] = useState<Agent[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [projectId, setProjectId] = useState(getProjectIdFromLocalStorage());
  const itemsPerPage = 9;

  // Atualiza o estado do projectId ao detectar mudanças no localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const newProjectId = getProjectIdFromLocalStorage();
      setProjectId(newProjectId);
    };

    // Verifica mudanças no localStorage a cada segundo (para mudanças locais)
    const interval = setInterval(handleStorageChange, 1000);

    // Cleanup do intervalo
    return () => clearInterval(interval);
  }, []);

  // Atualiza o localStorage assim que a página for acessada (componente montado)
  useEffect(() => {
    const projectIdFromLocalStorage = getProjectIdFromLocalStorage();
    if (!localStorage.getItem('selectedProjectId')) {
      // Se o localStorage não tiver o valor de selectedProjectId, define um valor padrão
      localStorage.setItem('selectedProjectId', projectIdFromLocalStorage.toString());
    }
  }, []);

  // Busca os agentes sempre que o projectId mudar
  useEffect(() => {
    const getAgents = async () => {
      try {
        const agents = await fetchAgents(projectId);
        setAgentsData(agents);
      } catch (error) {
        console.error('Erro ao carregar agentes:', error);
      }
    };

    getAgents();
  }, [projectId]); // Atualiza ao mudar o projeto

  // Filtragem dos dados com base nos filtros aplicados
  const filteredData = useMemo(() => {
    return agentsData.filter(
      (agent) =>
        agent.name.toLowerCase().includes(searchName.toLowerCase()) &&
        (searchCategory === '' || agent.category === searchCategory) &&
        (searchStatus === '' || agent.status === searchStatus) &&
        agent.project === projectId // Aplica o filtro baseado no projeto
    );
  }, [agentsData, searchName, searchCategory, searchStatus, projectId]);

  // Paginação
  const paginatedData = useMemo(() => {
    return filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [filteredData, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Agentes" />

      <div className="mb-5 grid grid-cols-1 items-center gap-4 sm:grid-cols-3 lg:grid-cols-4">
        <input
          type="text"
          placeholder="Pesquisar por nome"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          className="relative z-20 mt-4 w-full appearance-none rounded border border-stroke bg-white px-5 py-3 outline-none transition focus:border-primary active:border-primary dark:border-grafite_ dark:bg-form-input dark:focus:border-grafite_"
        />

        <SelectGroupAgentCategory
          selectedOption={searchCategory}
          onCategoryChange={(value: string) => setSearchCategory(value)}
        />

        <SelectGroupAgentStatus
          selectedOption={searchStatus}
          onStatusChange={(value: string) => setSearchStatus(value)}
        />
        
        <div className="mt-4 flex justify-start">
          <Link
            to="Agentes/Criação de Agente"
            className="rounded-md bg-virtuspurple px-9 py-3 font-medium text-white hover:bg-opacity-90"
          >
            + Criar Agente
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-7.5 sm:grid-cols-2 xl:grid-cols-3">
        {paginatedData.map((agent, index) => (
          <CardsItemThree
            key={index}
            cardTitle={agent.name}
            cardStatus={agent.status}
            cardCategory={agent.category}
            cardContent={agent.description}
            cardProject={agent.project}
          />
        ))}
      </div>

      <div className="mt-5 flex items-center justify-between">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="rounded-md border p-2"
        >
          Anterior
        </button>
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="rounded-md border p-2"
        >
          Próxima
        </button>
      </div>
    </DefaultLayout>
  );
};

export default Agents;
