import React from 'react';
import { Link } from 'react-router-dom';
import DefaultLayout from '../../layout/DefaultLayout';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import ListAgentKn from '../../components/ListAgentKn';
import ListAgentskill from '../../components/ListAgentskill';

const AgentSkillsKnowledge: React.FC = () => {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="Habilidades e Conhecimento" />
      <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <ListAgentKn/>
        <ListAgentskill/>
      </div>

      <div className="flex items-center">
        <div className="mt-4 flex justify-start">
          <Link
            to="/"
            className="rounded-md bg-red px-9 py-3 font-medium text-white hover:bg-opacity-90"
          >
            Voltar para area de agentes
          </Link>
        </div>

        <div className="ml-2 mt-4 flex justify-start">
          <Link
            to="/Treinamento"
            className="rounded-md bg-virtuspurple px-9 py-3 font-medium text-white hover:bg-opacity-90"
          >
            Iniciar Treinamento
          </Link>
        </div>
      </div> 

    </DefaultLayout>
  );
};

export default AgentSkillsKnowledge;