// import React from 'react';
// import { Link } from 'react-router-dom';
// import { CardItemProps } from '../types/cards';

// const CardsItemThree: React.FC<CardItemProps> = ({
//   cardTitle,
//   cardContent,
// }) => {
//   return (
//     <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
//       <div className="border-b border-stroke p-5 px-7.5 dark:border-strokedark">
//         <h4 className="text-xl font-semibold text-black hover:text-primary dark:text-white dark:hover:text-primary">
//           <Link to="#">{cardTitle}</Link>
//           {/* <p className="inline-flex rounded bg-[#3CA745] py-1 px-2 text-sm font-medium text-white hover:bg-opacity-90">
//             Em uso
//           </p> */}
//         </h4>
//       </div>
//       <div className="px-7.5 pt-6 pb-9">
//         <p>{cardContent}</p>
//         <p className="inline-flex rounded-full bg-primary py-1 px-3 text-sm font-medium text-white hover:bg-opacity-90 mt-2">
//         {cardContent}</p>
//       </div>
//     </div>
//   );
// };

// export default CardsItemThree;
import React from 'react';
import { CardItemProps } from '../types/cards';
import { Link } from 'react-router-dom';

const CardsItemThree: React.FC<CardItemProps> = ({
  cardTitle,
  cardContent,
  cardProject,
  cardCategory,
  cardStatus,
}) => {
  return (
    <div className="rounded-lg bg-white shadow-default dark: dark:bg-boxdark">
      <div className="rounded-t-lg p-5 px-7.5 dark:border-strokedark">
        {/* Categoria como botão */}
        <p className="inline-flex rounded-full bg-azul20 bg-opacity-20 py-1 px-3 text-sm font-medium text-azul20 ">
          {cardCategory || 'Categoria indefinida'} {/* Fallback para categoria */}
        </p>
        <div className="flex justify-between items-center mt-2.5"> {/* Diminui a margem superior entre título e categoria */}
          {/* Título do Card */}
          <h4 className="text-xl font-semibold text-black dark:text-white dark: mb-0">
            {cardTitle} {/* Diminui a margem inferior do título */}
          </h4>
          <h4 className="text-xl font-semibold text-black dark:text-white dark: mb-0">
            {cardProject} {/* Teste */}
          </h4>
          {/* Exibe o status somente se for "Em uso" */}
          {cardStatus === 'Em uso' && (
            <p className="inline-flex rounded bg-verde20 bg-opacity-20 py-1 px-2 text-sm font-medium text-verde20 ml-3">
              {cardStatus}
            </p>
          )}
        </div>
      </div>
      <div className="rounded-b-lg px-7.5 pt-2.5 pb-9"> {/* Diminui a margem superior do conteúdo */}
        <p>{cardContent || 'Conteúdo indefinido'}</p> {/* Fallback para conteúdo */}
        {/* Botão baseado no status */}
        {cardStatus === 'Em uso' ? (
          <Link to="/dashboard/Criação de Agente">
            <button className="inline-flex items-center justify-center w-[105px] h-[40px] rounded bg-virtuspurple text-sm font-medium text-white hover:bg-opacity-90 mt-4">
              Editar
            </button>
          </Link>
        ) : (
          <Link to="/agent/modelo">
            <button className="inline-flex items-center justify-center w-[105px] h-[40px] rounded bg-primary text-sm font-medium text-white hover:bg-opacity-90 mt-4">
              Utilizar
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default CardsItemThree;
